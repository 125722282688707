/* eslint-disable no-nested-ternary */
/* eslint-disable default-case */
/* eslint-disable no-fallthrough */
import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Card from "@mui/material/Card";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// NewCourse page components
import CourseInfo from "layouts/courses/components/CoursePreview/components/CourseInfo";
import CourseDetails from "layouts/courses/components/CoursePreview/components/CourseDetails";
import Media from "layouts/courses/components/CoursePreview/components/Media";
import Lectures from "layouts/courses/components/CoursePreview/components/Lectures";
import Pricing from "layouts/courses/components/CoursePreview/components/Pricing";

import validations from "layouts/courses/components/CoursePreview/schemas/validations";
import { CreateCourse, AddLecturesToCourse, GetLecturesFromCourse } from "utils/apis/courses";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useMaterialUIController, setSuccessMSG, setErrorMSG } from "context";
import { Box } from "@mui/material";

function getSteps() {
  return [
    "1- المعلومات الأساسية",
    "2. التسعير والتصنيف",
    "3. التفاصيل",
    "4. الوسائط",
    "5. المحاظرات",
  ];
}

function getStepContent(stepIndex, state, getLecturesResults) {
  const { courseData, setCourseData, errors } = state;
  switch (stepIndex) {
    case 0:
      return (
        <CourseInfo
          localState={courseData.courseInfo}
          setParentState={setCourseData}
          errors={errors}
        />
      );
    case 1:
      return (
        <Pricing localState={courseData.pricing} setParentState={setCourseData} errors={errors} />
      );
    case 2:
      return (
        <CourseDetails
          localState={courseData.courseDetails}
          setParentState={setCourseData}
          errors={errors}
        />
      );
    case 3:
      return <Media localState={courseData.media} setParentState={setCourseData} errors={errors} />;

    case 4:
      return (
        <Lectures
          localState={courseData.content}
          setParentState={setCourseData}
          errors={errors}
          getLecturesResults={getLecturesResults}
        />
      );
    default:
      return null;
  }
}

function CreateNewCourse({ title, description }) {
  const [activeStep, setActiveStep] = useState(0);
  const [theCourseIsCreated, setTheCourseIsCreated] = useState(false);
  const steps = getSteps();
  const isLastStep = activeStep === steps.length - 1;
  const isDataOfCourseFinish = activeStep === 3;
  const currentValidation = validations[activeStep];
  const navigate = useNavigate();
  const [createdCourseId, setCreatedCourseId] = useState(null);
  const [controller, dispatch] = useMaterialUIController();
  const [successfulAddedLectures, setSuccessfulAddedLectures] = useState(0);

  const {
    mutateAsync,
    isError,
    isLoading,
    error: createError,
  } = useMutation({
    mutationFn: CreateCourse,
    onSuccess: (response) => {
      // navigate("/home/slides");
      setCreatedCourseId(response?.data?.id);
      setTheCourseIsCreated(true);
      setSuccessMSG(dispatch, "تمت عملية اضافة الكورس بنجاح");
    },
    onError: (response) => {
      setErrorMSG(dispatch, response?.response?.statusText);
    },
  });

  const [courseData, setCourseData] = useState({
    courseInfo: {
      courseName: null,
      description: null,
      teacherId: null,
      stageId: null,
      subjectId: null,
      // just for view
      selectedTeacherObj: null,
      selectedStageObj: null,
      selectedSubjectObj: null,
    },
    pricing: {
      price: null,
      expireDate: null,
      // is_featured the course be in sliders of home page
      is_featured: false,
      // is_recent: false,
      is_popular: false,
    },
    courseDetails: {
      whatWeProvide: Array(2)
        .fill()
        .map(() => ({
          icon: null,
          title: null,
          description: null,
          // just for view
          selectedWhatWeProvideIconObj: null,
        })),
      whatWeTeach: Array(3)
        .fill()
        .map(() => ({ description: null })),
    },
    media: {
      mobileScreenImage: null,
      wideScreenImage: null,
      trailers: [{ title: "نبذة عن الدورة", video: "" }],
      groupChatLink: null,
    },
    content: {
      lectures: [{ sort_id: 10, video: null, title: null, description: null, file: null }],
    },
  });

  const {
    data: getLecturesResults,
    error: getLecturesError,
    refetch,
  } = useQuery(["lectures", createdCourseId], () => GetLecturesFromCourse(createdCourseId), {
    enabled: false, // Disable automatic query execution
  });

  const {
    mutate: mutateForAddLectures,
    isError: isErrorForAddLectures,
    isLoading: isLoadingForAddLectures,
    error: errorForAddLectures,
  } = useMutation({
    mutationFn: ({ lecture, courseId }) => AddLecturesToCourse(lecture, courseId),
    onSuccess: (response) => {
      setSuccessfulAddedLectures((prevCount) => {
        const { title: lectureName } = response.data;
        setSuccessMSG(dispatch, `تمت اضافة المحاظرة ${lectureName} بنجاح`);
        if (prevCount == courseData.content.lectures.length - 1) {
          refetch();
          courseData.content.lectures = [
            { sort_id: 10, video: null, title: null, description: null, file: null },
          ];
          navigate("/home/stages-and-subjects");
        }
        return prevCount + 1;
      });
    },
    onError: (response) => {
      setErrorMSG(dispatch, response?.response?.statusText);
    },
  });

  const [errors, setErrors] = useState({});

  const handleNext = async () => {
    try {
      switch (activeStep) {
        case 0: {
          setErrors({});
          await currentValidation.validate(courseData.courseInfo, { abortEarly: false });
          break; // Prevent fall-through
        }

        case 1: {
          setErrors({});
          await currentValidation.validate(courseData.pricing, { abortEarly: false });
          break; // Prevent fall-through
        }

        case 2: {
          setErrors({});
          await currentValidation.validate(courseData.courseDetails, { abortEarly: false });
          break; // Prevent fall-through
        }

        case 3: {
          setErrors({});
          await currentValidation.validate(courseData.media, { abortEarly: false });
          break; // Prevent fall-through
        }
      }

      setActiveStep(activeStep + 1);
    } catch (error) {
      const newErrors = {};
      if (error.inner) {
        error.inner.forEach((err) => {
          newErrors[err.path] = err.message;
        });
      }

      setErrors(newErrors);
    }
  };

  const handleBack = () => setActiveStep(activeStep - 1);

  const addCourse = async () => {
    try {
      setErrors({});
      await currentValidation.validate(courseData.media, { abortEarly: false });
      return await mutateAsync({
        name: courseData.courseInfo.courseName,
        teacher_id: Number(courseData.courseInfo.teacherId),
        stage_id: courseData.courseInfo.stageId,
        subject_id: courseData.courseInfo.subjectId,
        description: courseData.courseInfo.description,
        price: Number(courseData.pricing.price),
        expire_date: courseData.pricing.expireDate,
        is_featured: courseData.pricing.is_featured,
        is_popular: courseData.pricing.is_popular,
        what_we_provide: courseData.courseDetails.whatWeProvide,
        what_we_teach: courseData.courseDetails.whatWeTeach,
        trailers: courseData.media.trailers,
        mobile_screen_image: courseData.media.mobileScreenImage,
        wide_screen_image: courseData.media.wideScreenImage,
        group_chat_link: courseData.media.groupChatLink,
      });
    } catch (error) {
      const newErrors = {};
      if (error.inner) {
        error.inner.forEach((err) => {
          newErrors[err.path] = err.message;
        });
      }

      setErrors(newErrors);
    }
  };

  const addLecturesToCourse = (courseId) => {
    courseData.content.lectures.forEach((lecture, index) => {
      mutateForAddLectures({ lecture, courseId });
      // if (index == courseData.content.lectures?.length - 1) {

      // }
    });
  };

  const addCourseWithLectures = async () => {
    try {
      // last validation

      setErrors({});
      await currentValidation.validate(courseData.content, { abortEarly: false });
      if (!theCourseIsCreated) {
        // create the course first
        const result = await addCourse();

        const { status, statusText, data } = result;

        if (status == 201 && statusText == "Created") {
          // courseData.content.lectures.forEach((lecture) => {
          //   mutateForAddLectures(lecture, data?.id);
          // });
          addLecturesToCourse(data?.id);
        }
      } else {
        // mutateForAddLectures({ ...courseData.content.lectures[0] }, createdCourseId);
        // courseData.content.lectures.forEach((lecture) => {
        //   mutateForAddLectures(lecture, createdCourseId);
        // });
        addLecturesToCourse(createdCourseId);
      }
    } catch (error) {
      const newErrors = {};
      if (error.inner) {
        error.inner.forEach((err) => {
          newErrors[err.path] = err.message;
        });
      }

      setErrors(newErrors);
    }
  };

  return (
    <MDBox mt={1}>
      <Grid container justifyContent="center">
        <Grid item xs={12} lg={8}>
          <MDBox mb={6} textAlign="center">
            <MDBox mb={1}>
              <MDTypography variant="h3" fontWeight="bold">
                {title}
              </MDTypography>
            </MDBox>
            <MDTypography variant="h5" fontWeight="regular" color="secondary">
              {description}
            </MDTypography>
          </MDBox>
          <Card>
            <MDBox mt={-3} mb={3} mx={2}>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </MDBox>

            <MDBox p={2}>
              <MDBox>
                {getStepContent(
                  activeStep,
                  { courseData, setCourseData, errors },
                  getLecturesResults
                )}
                <MDBox mt={3} width="100%" display="flex" justifyContent="space-between">
                  {activeStep === 0 ? (
                    <MDBox />
                  ) : (
                    <MDButton variant="gradient" color="light" onClick={handleBack}>
                      رجوع
                    </MDButton>
                  )}
                  <Box sx={{ display: "flex", gap: 2 }}>
                    {isDataOfCourseFinish && (
                      <MDButton
                        variant="gradient"
                        color="dark"
                        onClick={addCourse}
                        disabled={theCourseIsCreated || isLoading}
                      >
                        انشاء الكورس
                      </MDButton>
                    )}

                    {!isLastStep ? (
                      <MDButton variant="gradient" color="dark" onClick={handleNext}>
                        التالي
                      </MDButton>
                    ) : (
                      <MDButton
                        variant="gradient"
                        color="dark"
                        onClick={addCourseWithLectures}
                        disabled={isLoadingForAddLectures || isLoading}
                      >
                        {theCourseIsCreated ? "اضافة محاظرات" : "انشاء الكورس واضافة المحاظرات"}
                      </MDButton>
                    )}
                  </Box>
                </MDBox>
              </MDBox>
              {isErrorForAddLectures && (
                <MDBox mt={2} mb={1}>
                  <MDTypography
                    variant="caption"
                    display="block"
                    color="error"
                    sx={{ textAlign: "center", fontWeight: "bold" }}
                  >
                    {errorForAddLectures?.response?.data?.message}
                  </MDTypography>
                </MDBox>
              )}

              {isError && (
                <MDBox mt={2} mb={1}>
                  <MDTypography
                    variant="caption"
                    display="block"
                    color="error"
                    sx={{ textAlign: "center", fontWeight: "bold" }}
                  >
                    {createError?.response?.data?.message}
                  </MDTypography>
                </MDBox>
              )}
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default CreateNewCourse;
