import * as Yup from "yup";

// Schema for a single item in the whatWeProvide array
const lecturesItemSchema = Yup.object().shape({
  sort_id: Yup.number().nullable().required("التسلسل مطلوب"),
  video: Yup.string().nullable().required("الفديو مطلوب"),
  title: Yup.string().nullable().required("العنوان مطلوب"),
  description: Yup.string().nullable().required("الوصف مطلوب"),
  file: Yup.mixed().required("ملفات الصور مطلوبة لكل محاظرة"),
});

const whatWeProvideItemSchema = Yup.object().shape({
  icon: Yup.string().nullable(),
  title: Yup.string().nullable().required("العنوان مطلوب"),
  description: Yup.string().nullable().required("الوصف مطلوب"),
});

const whatWeTeachItemSchema = Yup.object().shape({
  description: Yup.string().nullable().required("الوصف مطلوب"),
});

const trailersItemSchema = Yup.object().shape({
  title: Yup.string().nullable().required("العنوان مطلوب"),
  video: Yup.string().nullable().required("الفديو مطلوب"),
});

export default [
  Yup.object().shape({
    courseName: Yup.string().nullable().required("اسم الكورس ليس اختياري"),
    description: Yup.string().nullable().required("وصف الكورس ليس اختياري"),
    teacherId: Yup.number().nullable().required("يجب اختيار مدرس"),
    stageId: Yup.number().nullable().required("يجب اختيار مرحلة دراسية"),
    subjectId: Yup.number().nullable().required("يجب اختيار مادة دراسية"),
    // [password.name]: Yup.string().required(password.errorMsg).min(6, password.invalidMsg),
    // [password.name]: Yup.string().required(password.errorMsg).min(6, password.invalidMsg),
    // [repeatPassword.name]: Yup.string()
    //   .required(repeatPassword.errorMsg)
    //   .oneOf([Yup.ref("password"), null], repeatPassword.invalidMsg),
  }),
  Yup.object().shape({
    price: Yup.number().nullable().required("يجب تحديد سعر الكورس"),
    expireDate: Yup.date()
      .nullable()
      .min(new Date(), "يجب ان يكون هذا التاريخ احدث من ذلك")
      .required("يجب تحديد تاريخ نهاية الكورس"),
  }),
  Yup.object().shape({
    whatWeProvide: Yup.array()
      .of(whatWeProvideItemSchema)
      .min(2, "يجب ان يكون على الأقل هناك ثلاث عناصر كاملة في ماذا نقدم"),
    whatWeTeach: Yup.array()
      .of(whatWeTeachItemSchema)
      .min(3, "يجب ان يكون على الأقل هناك ثلاث عناصر كاملة في ماذا نعلم"),
  }),
  Yup.object().shape({
    trailers: Yup.array()
      .of(trailersItemSchema)
      .min(1, "يجب ان يكون هناك فديو تعريفي واحد على الأقل"),
    groupChatLink: Yup.string().nullable().required("اضافة كروب امر اجباري وليس اختياري"),
  }),
  Yup.object().shape({
    lectures: Yup.array().of(lecturesItemSchema).min(1, "يجب ان يكون هناك فديو واحد على الأقل"),
  }),
];
