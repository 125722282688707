// @mui material components
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import TabLayout from "examples/TabLayout";

import Slide from "./components/Slide";
import MDButton from "components/MDButton";
import { Link } from "react-router-dom";

import { GetSlides } from "utils/apis/home";
import { useQuery } from "react-query";
import { useEffect, useMemo, useState } from "react";
import useStages from "hooks/useStages";
import { Box } from "@mui/material";

import NoDataImage from "assets/images/no-data.svg";
import NotFoundComponent from "components/NotFoundComponent";
import LoadingComponent from "examples/LoadingComponent";

function Slides() {
  const { stagesData } = useStages();
  const [activeTab, setActiveTab] = useState();
  const [selectedStageId, setSelectedStageId] = useState();
  const [slides, setSlides] = useState([]);

  const stages = useMemo(() => {
    const arrayOfStages = stagesData?.map((stageData) => stageData?.name);
    setActiveTab(arrayOfStages?.[0]);
    return arrayOfStages;
  }, [stagesData]);

  // change stageId when active tab change
  useEffect(() => {
    const targetStage = stagesData?.filter((stageData) => stageData.name === activeTab);
    setSelectedStageId(targetStage?.[0]?.id);
  }, [activeTab]);

  const { data: slidesData, isLoading } = useQuery(
    ["slides", selectedStageId],
    () => GetSlides(selectedStageId),
    {
      enabled: !!selectedStageId,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      onSuccess: (data) => setSlides(data?.data || []),
    }
  );

  // Sort the slides based on the sort_number property
  const sortedSlides = useMemo(() => {
    return slides?.sort((a, b) => a.sort_number - b.sort_number);
  }, [slides]);

  const handleDeleteLocalSlide = (id) => {
    setSlides((prevSlides) => prevSlides.filter((slide) => slide.id !== id));
  };

  const upperComponent = (
    <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mt={3}>
      <Link to="/home/slides/create">
        <MDButton variant="gradient" color="info">
          سلايد جديد
        </MDButton>
      </Link>
    </MDBox>
  );

  const renderSlidesContent = () => {
    if (isLoading || !sortedSlides) {
      return <LoadingComponent />;
    }

    if (sortedSlides?.length === 0) {
      return <NotFoundComponent />;
    }

    return (
      <Grid container spacing={2} sx={{ marginBlock: 1 }}>
        {sortedSlides?.map((slide) => (
          <Grid item lg={6} key={slide?.id}>
            <Slide slide={slide} onDelete={handleDeleteLocalSlide} />
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <TabLayout
      tabs={stages}
      setActiveTab={setActiveTab}
      activeTab={activeTab}
      xlGridNumber={12}
      upperComponent={upperComponent}
    >
      {renderSlidesContent()}
    </TabLayout>
  );
}

export default Slides;
