import axiosInstance from "utils/axiosInstance";
import { createFile } from "utils/methods";
export const GetCourses = async () => {
  const response = await axiosInstance.get("/api/dashboard/courses/list");

  return response;
};

export const GetCourseById = async (courseId) => {
  const response = await axiosInstance.get(`/api/dashboard/courses/${courseId}`);

  return response;
};

export const CreateCourse = async (courseData) => {
  const formData = new FormData();
  formData.append("name", courseData.name);
  formData.append("teacher_id", courseData.teacher_id);
  formData.append("stage_id", courseData.stage_id);
  formData.append("subject_id", courseData.subject_id);
  formData.append("description", courseData.description);
  formData.append("price", courseData.price);
  formData.append("expire_date", courseData.expire_date);
  formData.append("is_featured", courseData.is_featured);
  formData.append("is_popular", courseData.is_popular);
  formData.append("what_we_provide", JSON.stringify(courseData.what_we_provide));
  formData.append("what_we_teach", JSON.stringify(courseData.what_we_teach));
  formData.append("trailers", JSON.stringify(courseData.trailers)); // assuming trailers is an array
  formData.append("mobile_screen_image", courseData.mobile_screen_image);
  formData.append("wide_screen_image", courseData.wide_screen_image);
  formData.append("group_chat_link", courseData.group_chat_link);

  const response = await axiosInstance.post("/api/dashboard/courses/create", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response;
};

export const EditCourse = async (courseData, courseId) => {
  const formData = new FormData();

  if (courseData?.name) formData.append("name", courseData.name);
  if (courseData?.teacher_id) formData.append("teacher_id", courseData.teacher_id);
  if (courseData?.stage_id) formData.append("stage_id", courseData.stage_id);
  if (courseData?.subject_id) formData.append("subject_id", courseData.subject_id);
  if (courseData?.description) formData.append("description", courseData.description);
  if (courseData?.price) formData.append("price", courseData.price);
  if (courseData?.expire_date) formData.append("expire_date", courseData.expire_date);
  formData.append("is_featured", courseData.is_featured);
  formData.append("is_popular", courseData.is_popular);

  if (courseData?.what_we_provide) {
    const updatedWhatWeProvide = courseData?.what_we_provide.map(
      ({ selectedWhatWeProvideIconObj, ...rest }) => rest
    );
    formData.append("what_we_provide", JSON.stringify(updatedWhatWeProvide));
  }

  if (courseData?.what_we_teach)
    formData.append("what_we_teach", JSON.stringify(courseData.what_we_teach));
  if (courseData?.trailers) formData.append("trailers", JSON.stringify(courseData.trailers)); // assuming trailers is an array
  if (courseData?.mobile_screen_image) {
    if (courseData.mobile_screen_image instanceof File) {
      formData.append("mobile_screen_image", courseData.mobile_screen_image);
    } else {
      const newFile = await createFile(courseData.mobile_screen_image);
      formData.append("mobile_screen_image", newFile);
    }
  }

  if (courseData?.wide_screen_image) {
    if (courseData.wide_screen_image instanceof File) {
      formData.append("wide_screen_image", courseData.wide_screen_image);
    } else {
      const newFile = await createFile(courseData.wide_screen_image);
      formData.append("wide_screen_image", newFile);
    }
  }

  if (courseData?.group_chat_link) formData.append("group_chat_link", courseData.group_chat_link);

  const response = await axiosInstance.put(`/api/dashboard/courses/${courseId}/update`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response;
};

export const DeleteCourse = async (courseId) => {
  const response = await axiosInstance.delete(`/api/dashboard/courses/${courseId}/delete   `);
  return response;
};

export const AddLecturesToCourse = async (lectureData, courseId) => {
  const formData = new FormData();
  formData.append("title", lectureData.title);
  formData.append("description", lectureData.description);
  formData.append("video", lectureData.video);
  formData.append("sort_id", lectureData.sort_id);
  formData.append("file", lectureData.file);

  const response = await axiosInstance.post(
    `/api/dashboard/course/${courseId}/lectures`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return response;
};

export const EditLecturesOfCourse = async (lectureData, courseId) => {
  const formData = new FormData();
  formData.append("title", lectureData.title);
  formData.append("description", lectureData.description);
  formData.append("video", lectureData.video);
  formData.append("sort_id", lectureData.sort_id);
  formData.append("file", lectureData.file);

  const response = await axiosInstance.put(
    `/api/dashboard/course/${courseId}/lectures/${lectureData?.id}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return response;
};

export const deleteLectureFromCourse = async (courseId, lectureId) => {
  const response = await axiosInstance.delete(
    `/api/dashboard/course/${courseId}/lectures/${lectureId}`
  );
  return response;
};

export const GetLecturesFromCourse = async (courseId) => {
  const response = await axiosInstance.get(`/api/dashboard/course/${courseId}/lectures`);

  return response;
};

export const GetIcons = async () => {
  const response = await axiosInstance.get("api/dashboard/courses/icons");

  return response;
};

export const GetSpecialCourses = async (stageId) => {
  const response = await axiosInstance.get(`/api/dashboard/${stageId}/special-courses`);

  return response;
};

export const AddSpecialCourse = async (specialCourseData) => {
  const response = await axiosInstance.post("api/dashboard/special-courses", specialCourseData);

  return response;
};
