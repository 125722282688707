import axiosInstance from "utils/axiosInstance";

export const GetSlides = async (stageId) => {
  // eslint-disable-next-line no-promise-executor-return
  await new Promise((resolve) => setTimeout(resolve, 3000));
  const response = await axiosInstance.get(`api/dashboard/${stageId}/sliders`);

  return response;
};

export const AddSlide = async (SlideData) => {
  const response = await axiosInstance.post("api/dashboard/sliders", SlideData);

  return response;
};

export const EditSlide = async (SlideData, slideId) => {
  console.log("RRR", SlideData);
  const response = await axiosInstance.put(`api/dashboard/sliders/${slideId}`, SlideData);

  return response;
};

export const DeleteSlide = async (sliderId) => {
  const response = await axiosInstance.delete(`/api/dashboard/sliders/${sliderId}`);
  return response;
};

export const GetStages = async () => {
  const response = await axiosInstance.get("api/dashboard/stages");

  return response;
};

export const GetSubjectsAndCourses = async (stageId) => {
  const response = await axiosInstance.get(`api/dashboard/stages/${stageId}/subjects`);

  return response;
};
